import "./error.css";

const index = () => {
  return (
    <>
    <div className="main">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="470"
        height="290"
        viewBox="0 0 470 290"
      >
        <defs>
          <path
            className="fundo"
            id="prefix__a"
            d="M5.063 128.67c-2.513 15.192 5.633 31.093 17.898 38.941 5.99 3.832 13.34 6.528 16.471 13.254 4.868 10.452-3.879 22.437-13.113 28.515-9.236 6.078-20.5 10.9-24.704 21.683-2.771 7.108-1.685 15.387 1.058 22.507 10.06 26.112 39.393 37.547 65.479 36.15 26.086-1.396 50.827-12.407 76.416-18.075 87.873-19.465 180.005 24.717 267.728 4.47 13.65-3.151 27.4-8.081 37.943-17.99 11.883-11.167 18.632-28.016 19.65-45.023.97-16.225-4.34-34.495-17.744-41.806-7.834-4.273-17.196-4.1-25.7-1.774-5.43 1.483-10.767 3.808-16.369 3.848-5.601.038-11.763-3-13.386-8.808-1.707-6.107 2.182-12.41 6.642-16.577 9.072-8.474 21.203-12.707 29.441-22.126 7.927-9.063 11.264-22.574 8.574-34.716-2.692-12.141-11.326-22.538-22.188-26.715-27.683-10.645-57.844 18.377-86.152 9.873-2.101-.63-4.312-1.605-5.418-3.641-1.08-1.988-.834-4.51-.214-6.716 3.468-12.348 16.939-20.21 17.528-33.102.32-7.008-3.504-13.564-8.325-18.251-33.126-32.2-81.125 6.102-114.9 18.194-55.542 19.884-112.157 36.49-167.849 55.963-20.81 7.275-44.91 18.606-48.766 41.922z"
          />
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            fill="#FFF"
            d="M0 0H1366V800H0z"
            transform="translate(-448 -157)"
          />
          <g transform="translate(-448 -157) translate(448 157)">
            <mask id="prefix__b" fill="#fff">
              <use xlinkHref="#prefix__a" />
            </mask>
            <use fill="#F6F6F7" xlinkHref="#prefix__a" />
            <path
              fill="#EDEDF0"
              fillRule="nonzero"
              d="M-14.199 211.2H481.36V301.2H-14.199z"
              mask="url(#prefix__b)"
            />
            <g className="paes">
              <g className="pao-baixo">
                <path
                  fill="#FBB965"
                  d="M2.79 131.737s-2.073 3.155-2.479 6.868c-.406 3.713-.747 9.666 1.24 13.372 1.985 3.707 12.69 20.8 65.175 21.02 53.15.225 69.188-15.685 70.59-18.977 2.605-6.118 1.838-21.327.06-22.283-1.777-.956-44.044-3.204-72.446-4.057-28.402-.854-49.872-1.968-62.14 4.057"
                  transform="translate(161 68)"
                />
                <path
                  fill="#E6A95F"
                  d="M34.648 167.758c-8.863-1.526-23.515-6.939-30.292-14.218-6.775-7.28-2.096-8.803 3.508-5.387 5.605 3.415 24.569 11.557 54.124 12.263 29.555.706 61.424-6.946 72.2-17.053 0 0 2.705-1.47 2.768 1.509.062 2.98.428 7.948-2.769 10.507-3.196 2.558-34.805 23.526-99.54 12.379"
                  transform="translate(161 68)"
                />
                <path
                  fill="#FFDA7F"
                  d="M5.679 131.837c-6.522 1.646-.275 6.91 9.492 12.14 9.767 5.229 28.24 10.257 44.267 10.015 16.028-.243 37.48-.481 52.543-5.333 15.06-4.852 16.223-9.55 17.998-13.298 1.774-3.748-107.32-7.809-124.3-3.524"
                  transform="translate(161 68)"
                />
              </g>
              <g className="pao-cima">
                <path
                  fill="#FBB868"
                  d="M71.37 0C49.008.035-2.43.631 1.18 51.16c0 0-.018 10.84 62.825 10.84 62.844 0 72.591-9.633 73.721-11.173C142.284 44.623 147.583-.117 71.37 0"
                  transform="translate(161 68)"
                />
                <path
                  fill="#E6A95F"
                  d="M34.552 61c-7.628-1.006-23.98-2.904-27.586-5.506-3.606-2.604-7.448-2.895-5.39-10.826.842-3.242 7.976-.619 11.264.839 3.289 1.458 21.239 6.047 42.989 6.673 21.75.625 57.126-1.679 67.42-5.458 9.806-3.598 13.662-7.027 15.493-5.228 2.396 2.351 1.687 8.008-4.913 12.215-6.252 3.985-27.53 7.2-49.434 7.76-21.904.56-38.604 1.012-49.843-.469"
                  transform="translate(161 68)"
                />
                <path
                  fill="#FFEAD4"
                  d="M45.508 13.114c-.368.549-.54 1.598-.503 2.445.017.392.297.604.45.287.143-.297.222-.617.303-.978.087-.387.197-.735.238-1.15.042-.44-.257-.95-.488-.604M42.092 9.016c-.694.13-1.446.61-1.774 1.098-.168.248-.3.512-.317.792-.017.313.154.503.29.776.249.494 1.245.392 1.22-.162-.014-.274.33-.612.54-.817.367-.361.75-.62.923-1.075.154-.404-.413-.7-.882-.612M51.621 16.138c-.117.354-.115.679-.005 1.034.153.533.659.725.906.262.244-.463.018-1.083-.36-1.494-.392-.48-1.124-.34-1.415.15-.356.506-.405 1.142-.224 1.721.212.623.63 1.192 1.266 1.456.33.129.697.21 1.043.244.372.04.746-.015 1.107-.14.298-.096.602-.285.775-.548.195-.32.25-.72.077-1.048-.188-.353-.59-.515-.93-.57-.074-.016-.147-.027-.218-.04M24.167 56.608c-2.466-1.872-6.108-4.114-5.654-7.218.377-2.168 2.952-1.384 4.053-1.03.01 0 .017.004.024.008 1.486.535 2.594 1.366 2.5 2.284-.088 1.185-2.152 1.646-3.036 1.56-.87-.079-1.163-.964-.94-1.573.283-.607 1.055-1.036 1.656-1.167M26.075 60.602c.784-1.226 1.315-2.704 1.275-4.15-.04-1.446-.812-3.025-2.051-4.008-1.22-.973-3.091-1.56-4.695-1.586-1.11-.018-2.563.243-2.728 1.523-.165 1.281 1.313 1.675 2.07 1.8 1.25.185 2.623.541 3.487 1.24.23.209.46.464.37.69-.032.072-.095.137-.176.126M31.558 66.896c-.633.636-1.606.979-2.33 1.014-.72.03-1.547-.297-1.718-.972-.075-.32.06-.664.203-.877.33-.423.961-.496 1.475-.506.213-.004.427.003.637.036.325.056.655.15.984.25.275.093.48.344.546.623.084.35.017.739-.204 1.019M17.736 49.166c-1.3-.127-2.66-.45-3.572-1.13-.919-.685-1.586-1.465-1.506-2.356.083-.794.767-1.174 1.558-1.169 1.584.008 3.29.974 3.633 2.299.21.924-1.59 2.068-2.257 2.258"
                  transform="translate(161 68)"
                />
                <g className="olhos">
                  <path
                    fill="#633"
                    d="M51.976 32.505c.27 2.748-1.735 5.197-4.476 5.47-2.748.274-5.199-1.732-5.476-4.48-.27-2.748 1.735-5.197 4.483-5.47 2.748-.274 5.192 1.733 5.469 4.48M93.976 28.505c.27 2.748-1.735 5.197-4.483 5.47-2.748.273-5.192-1.733-5.469-4.48-.27-2.748 1.735-5.197 4.483-5.47 2.748-.274 5.192 1.733 5.469 4.48M65.03 45.127c2.1-5.726 9.106-6.606 13.113-2.171.408.462-.277 1.204-.725.77-3.981-3.892-9.17-2.951-11.83 1.745-.187.333-.68-.002-.558-.344"
                    transform="translate(161 68)"
                  />
                </g>
              </g>
            </g>
            <g
              fillRule="nonzero"
              stroke="#1471ab"
              strokeLinecap="round"
              strokeWidth="1.8"
              className="left-sparks"
            >
              <path d="M23.684 5.789L30 1.158" transform="rotate(-90 157 13)" />
              <path
                d="M0 5.789L6.316 1.158"
                transform="rotate(-90 157 13) matrix(-1 0 0 1 6.316 0)"
              />
              <path d="M15.789 4.632L15.789 0" transform="rotate(-90 157 13)" />
            </g>
            <g
              fillRule="nonzero"
              stroke="#1471ab"
              strokeLinecap="round"
              strokeWidth="1.8"
              className="right-sparks"
            >
              <path
                d="M23.684 5.789L30 1.158"
                transform="matrix(0 -1 -1 0 318 170)"
              />
              <path
                d="M0 5.789L6.316 1.158"
                transform="matrix(0 -1 -1 0 318 170) matrix(-1 0 0 1 6.316 0)"
              />
              <path
                d="M15.789 4.632L15.789 0"
                transform="matrix(0 -1 -1 0 318 170)"
              />
            </g>
            <path
              fill="#4B4B62"
              className="path"
              fillRule="nonzero"
              stroke="#4B4B62"
              strokeWidth="2"
              d="M198.754 186c1.56 0 2.246-.703 2.246-2.3v-41.4c0-1.597-.686-2.3-2.246-2.3h-9.608c-1.56 0-2.247.703-2.247 2.3v19.678h-5.802c-1.185 0-1.934-.83-1.934-2.172V142.3c0-1.597-.686-2.3-2.246-2.3h-9.67c-1.56 0-2.247.703-2.247 2.3v22.425c0 7.283 3.244 10.606 11.355 10.606H186.9v8.369c0 1.597.687 2.3 2.247 2.3h9.608zm32.277 1c15.3 0 18.969-5.248 18.969-13.056V152.12c0-7.808-3.67-13.12-18.969-13.12-15.3 0-19.031 5.312-19.031 13.12v21.824c0 7.808 3.732 13.056 19.031 13.056zm.969-12c-4.25 0-5-1.27-5-2.986v-17.091c0-1.652.75-2.923 5-2.923 4.313 0 5 1.27 5 2.923v17.09c0 1.716-.688 2.987-5 2.987zm62.754 11c1.56 0 2.246-.703 2.246-2.3v-41.4c0-1.597-.686-2.3-2.246-2.3h-9.608c-1.56 0-2.247.703-2.247 2.3v19.678h-5.802c-1.185 0-1.934-.83-1.934-2.172V142.3c0-1.597-.686-2.3-2.246-2.3h-9.67c-1.56 0-2.247.703-2.247 2.3v22.425c0 7.283 3.244 10.606 11.355 10.606H282.9v8.369c0 1.597.687 2.3 2.247 2.3h9.608z"
            />
          </g>
        </g>
      </svg>
    </div>
    <div className="text">
        <h1>Oops! a pagina não foi encontrada.</h1>
    </div>
    </>
  );
};

export default index;



import coracaoDeFrango from '../../../imagens/aves/coracao-de-frango.png';
import meioDaAsa from '../../../imagens/aves/meio-da-asa.png';
import sassami from '../../../imagens/aves/sassami.png';
import fileDeFrango from '../../../imagens/aves/File-de-Frango.png';
import fileDeCoxa from '../../../imagens/aves/file-de-coxa.png';
import asaDeFrango from '../../../imagens/aves/asa-de-frango.jpg';
import peitoDeFrango from '../../../imagens/aves/peito-de-frango.jpg';
import coxinhaDaAsa from '../../../imagens/aves/coxinha-da-asa.png';
import coxaSobrecoxa from '../../../imagens/aves/coxa-e-sobrecoxa.png';
import carcacaoDeFrango from '../../../imagens/aves/carcaca-de-frango.png';
import frangoResfriado from '../../../imagens/aves/frango-inteiro.png';
import moela from '../../../imagens/aves/moela.png';
import galinha from '../../../imagens/aves/galinha-e-galo.png';

import picanha from '../../../imagens/bovinos/picanha.png'
import fileMignon from '../../../imagens/bovinos/file-mignon.png'
import patinho from '../../../imagens/bovinos/patinho.png'
import alcatra from '../../../imagens/bovinos/alcatra-com-maminha.jpg'
import coxaoDuro from '../../../imagens/bovinos/coxao-duro.png'
import coxaoMole from '../../../imagens/bovinos/coxao-mole.png'
import capaFile from '../../../imagens/bovinos/capa-de-file.png'
import peitoBovino from '../../../imagens/bovinos/peito-bovino.png'
import acem from '../../../imagens/bovinos/acem.png'
import costelaBovina from '../../../imagens/bovinos/costela-bovina.png'
import carneMoida from '../../../imagens/bovinos/carne-moida.png'

import barriga from '../../../imagens/suinos/barriga.png'
import costelinha from '../../../imagens/suinos/costelinha.png'
import carre from '../../../imagens/suinos/carre.png'
import bisteca from '../../../imagens/suinos/bisteca.png'
import pernil from '../../../imagens/suinos/Pernil.png'
import tripa from '../../../imagens/suinos/tripa suina.png'
import papada from '../../../imagens/suinos/papada.png'
import paleta from '../../../imagens/suinos/Sobre Paleta.png'
import toucinhoPele from '../../../imagens/suinos/toucinho com pele.png'
import toucinhoCortado from '../../../imagens/suinos/toucinho cortado.png'

import batataBemBrasil from '../../../imagens/congelados/batata bem brasil.png'
import batataCarinha from '../../../imagens/congelados/batata carinhas.png'
import batataChic from '../../../imagens/congelados/batata chic.png'
import batataCrinkle from '../../../imagens/congelados/batata crinckle.png'
import batataFast from '../../../imagens/congelados/batata fast food.png'
import batataRustica from '../../../imagens/congelados/batata rustica.png'
import batataSteack from '../../../imagens/congelados/batata steak house.png'
import batataLamb from '../../../imagens/congelados/batata lamb weston.png'
import batataMaisBatata from '../../../imagens/congelados/batata mais batata.png'
import Stickqueijo from '../../../imagens/congelados/stick de queijo.png'
import anelCebola from '../../../imagens/congelados/anel de cebola.png'
import mandioca from '../../../imagens/congelados/mandioca.png'
import brocolis from '../../../imagens/congelados/brocolis.png'
import couve from '../../../imagens/congelados/Couve flor.png'
import seleta from '../../../imagens/congelados/Jardineira Seleta.png'
import polenta from '../../../imagens/congelados/Polenta.png'
import vagem from '../../../imagens/congelados/Design sem nome.png'

//novos
import batataMcCain from '../../../imagens/congelados/Batata McCain.png'
import chickenQueijo from '../../../imagens/congelados/chicken com queijo.png'
import chickenTradicional from '../../../imagens/congelados/chicken Tradicional.png'
import ervilhaCongelada from '../../../imagens/congelados/ervilha congelada.png'
import filezinhoEmpanado from '../../../imagens/congelados/Filezinho empanado.png'
import mandiocaCongelado from '../../../imagens/congelados/Mandioca Congelada.png'
import milhoCongelado from '../../../imagens/congelados/milho congelado.png'
import polentaCongelado from '../../../imagens/congelados/Polenta congelada.png'
import steakFrango from '../../../imagens/congelados/steak de frango.png'
import torresmoMoido from '../../../imagens/congelados/torresmo moido.png'
import hamburgue from '../../../imagens/congelados/hamburguer.png'


import bacalhau from '../../../imagens/peixes/bacalhau.png'
import merluzao from '../../../imagens/peixes/merluzao.png'
import merluzaArgentina from '../../../imagens/peixes/merluza argentina.png'
import fileTilapia from '../../../imagens/peixes/file de tilapia.png'
import filePangassius from '../../../imagens/peixes/file de pangassius.png'
import filePescadaBranca from '../../../imagens/peixes/file de pescada branca.png'
import cacao from '../../../imagens/peixes/cacao.png'
import porquinhoLimpo from '../../../imagens/peixes/porquinho limpo.png'
import sardinhaEspalmada from '../../../imagens/peixes/sardinha espalmada.png'
import sardinhaSemCabeca from '../../../imagens/peixes/sardinha sem cabeca.png'
import camarao7Barbas from '../../../imagens/peixes/camarao 7 barbas.png'

import bofeBovino from '../../../imagens/miudos/Bofe Bovino.png'
import buchoBovino from '../../../imagens/miudos/Bucho bovino.png'
import carneIndustrial from '../../../imagens/miudos/Carne industrial.png'
import coracaoBovino from '../../../imagens/miudos/Coracao Bovino.png'
import cupimBovino from '../../../imagens/miudos/Cupim Bovino.png'
import figadoBovino from '../../../imagens/miudos/Figado bovino.png'
import fressuraSuina from '../../../imagens/miudos/Fressura suina.png'
import linguaBovina from '../../../imagens/miudos/Lingua Bovina.png'
import mocotoBovino from '../../../imagens/miudos/Mocoto Bovino.png'
import mocotoCortado from '../../../imagens/miudos/Mocoto Cortado.png'
import passarinha from '../../../imagens/miudos/Passarinha.png'
import rabada from '../../../imagens/miudos/Rabada bovina.png'
import rimBovino from '../../../imagens/miudos/Rim Bovino.png'
import tripaBovina from '../../../imagens/miudos/tripa bovina.png'
import diafragma from '../../../imagens/miudos/Diafragma.png'
import sangria from '../../../imagens/miudos/Sangria Bovina.png'

import champignonFatiado from '../../../imagens/conservas/champignon fatiado.png'
import ovoCodorna from '../../../imagens/conservas/Ovo de codorna.png'
import palmitoPicado from '../../../imagens/conservas/palmito picado.png'
import palmitoRodela from '../../../imagens/conservas/Palmito Rodela.png'
import tomateSeco from '../../../imagens/conservas/Tomate Seco.png'
import pimentaBiquinho from '../../../imagens/conservas/pimenta biquinho.png'
import atumRalado from '../../../imagens/conservas/atum Ralado.png'
import chicharro from '../../../imagens/conservas/chicharro.png'
import azeitonaFatiada from '../../../imagens/conservas/azeitona verde fatiada.png'
import azeitonaComCaroco from '../../../imagens/conservas/azeitona com caroco.png'
import azeitonaSemCaroco from '../../../imagens/conservas/azeitona sem caroco.png'
import azeitonaPretaSemcaroco from '../../../imagens/conservas/azeitona preta sem caroco.png'
import azeitonaPretaFatiada from '../../../imagens/conservas/azeitona preta fatiada.png'
import azeitonaChilena from '../../../imagens/conservas/azeitona chilena.png'
import azeitonaBarrica from '../../../imagens/conservas/barrica de azeitona 28x32 - 16x20.png'
import chimichurri from '../../../imagens/conservas/chimichurri.png'

import amacianteCarne from '../../../imagens/conservas/amaciante de carne.png'
import caldoCarne from '../../../imagens/conservas/caldo de carne.png'
import caldoGalinha from '../../../imagens/conservas/caldo de galinha.png'
import colorifico from '../../../imagens/conservas/colorifico.png'
import cominho from '../../../imagens/conservas/cominho.png'
import condimentoPimenta from '../../../imagens/conservas/condimento penina.png'
import cremeCebola from '../../../imagens/conservas/creme de cebola.png'
import oreganoFlocos from '../../../imagens/conservas/oregano flocos.png'
import tempeiroBaiano from '../../../imagens/conservas/tempero baiano.png'
import feijaoCarioca from '../../../imagens/conservas/feijao carioca.png'
import feijaoPreto from '../../../imagens/conservas/Feijao preto.png'

import doceDeLeite from '../../../imagens/conservas/Doce de Leite.png'
import ketchupGalaoSache from '../../../imagens/conservas/ketchup Galao e Sache.png'
import maioneseBalseSache from '../../../imagens/conservas/Maionese balde e sache.png'
import molhoPizza from '../../../imagens/conservas/Molho de pizza.png'
import mostardaGalaoSache from '../../../imagens/conservas/Mostarda Galao e sache.png'


import costelaSalgada from '../../../imagens/feijoada/costela salgada.png'
import raboSalgado from '../../../imagens/feijoada/rabo salgado.png'
import pontaCostela from '../../../imagens/feijoada/ponta de costela.png'
import peSalgado from '../../../imagens/feijoada/pe salgado.png'
import orelhaSalgada from '../../../imagens/feijoada/orelha salgada.png'
import linguaSalgada from '../../../imagens/feijoada/lingua salgada.png'
import lomboSalgado from '../../../imagens/feijoada/lombo salgado.png'
import coxaoDuroFeijoada from '../../../imagens/feijoada/carne seca coxao duro.png'
import traseiroFeijoada from '../../../imagens/feijoada/carne seca traseiro.png'
import dianteiro from '../../../imagens/feijoada/carne seca dianteiro.png'


import queijoMussarela from '../../../imagens/lacteos/1Queijo Mussarela.png'
import queijoPrato from '../../../imagens/lacteos/2Queijo Prato.png'
import mortadela from '../../../imagens/lacteos/3Mortadela.png'
import presunto from '../../../imagens/lacteos/4Presunto.png'
import apresuntado from '../../../imagens/lacteos/Apresuntado.png'
import cheddarFatiado from '../../../imagens/lacteos/Cheddar Fatiado.png'
import margarinaPote from '../../../imagens/lacteos/Margarina pote.png'
import manteigaCaixa from '../../../imagens/lacteos/Mateiga Caixa com e sem sal.png'
import parmesãoInteiro from '../../../imagens/lacteos/Parmesao Inteiro.png'
import peitoDePeru from '../../../imagens/lacteos/Peito de Peru.png'
import provolone from '../../../imagens/lacteos/Provolone.png'
import queijoCoalho from '../../../imagens/lacteos/Queijo Coalho.png'
import queijoRalado from '../../../imagens/lacteos/Queijo Ralado.png'
import requeijãoBisnaga from '../../../imagens/lacteos/Requeijao Bisnaga.png'
import requeijãoPote from '../../../imagens/lacteos/Requeijao Pote.png'
import requeijãoDoNorte from '../../../imagens/lacteos/Requeijao do Norte.png'
import salame from '../../../imagens/lacteos/Salame.png'
import cheddarBisnaga from '../../../imagens/lacteos/cheddar Bisnaga.png'




const data = {
    cardData: [    
        
        { id: 1, img: batataBemBrasil, title: 'Batata Bem Brasil (Pct 1,05kg)', category: 'congelados' },
        { id: 2, img: batataBemBrasil, title: 'Batata Bem Brasil (Pct 2,5kg)', category: 'congelados' },
        { id: 3, img: batataCarinha, title: 'Batata Bem Brasil Carinhas', category: 'congelados' },
        { id: 4, img: batataChic, title: 'Batata Bem Brasil Chic', category: 'congelados' },
        { id: 5, img: batataCrinkle, title: 'Batata Bem Brasil Crinkle (Pct 2kg)', category: 'congelados' },
        { id: 6, img: batataFast, title: 'Batata Bem Brasil Fast Food 7mm (Pct 2kg)', category: 'congelados' },
        { id: 7, img: batataRustica, title: 'Batata Bem Brasil Rústica (Pct 2,5kg)', category: 'congelados' },
        { id: 8, img: batataSteack, title: 'Batata Bem Brasil Steakhouse 9x18 (Pct 2,5kg)', category: 'congelados' },
        { id: 9, img: batataLamb, title: 'Batata Lamb Weston Stealth Fries (Pct 2kg)', category: 'congelados' },
        { id: 10, img: batataMaisBatata, title: 'Batata Mais Batata (Pct 1,5kg)', category: 'congelados' },
        { id: 11, img: batataMaisBatata, title: 'Batata Mais Batata (Pct 2kg)', category: 'congelados' },
        { id: 12, img: batataMaisBatata, title: 'Batata Mais Batata (Pct 2,5kg)', category: 'congelados' },
        { id: 13, img: Stickqueijo, title: 'Stick de Queijo Bem Brasil (Pct 1,2kg) -', category: 'congelados' },
        { id: 14, img: anelCebola, title: 'Anel de Cebola Bem Brasil (Pct 1,05kg)', category: 'congelados' },
        { id: 15, img: anelCebola, title: 'Anel de Cebola Bem Brasil (Pct 400gr)', category: 'congelados' },
        { id: 16, img: mandioca, title: 'Mandioca Pedaços Canção', category: 'congelados' },
        { id: 17, img: brocolis, title: 'Brócolis', category: 'congelados' },
        { id: 18, img: couve, title: 'Couve Flor', category: 'congelados' },
        { id: 19, img: seleta, title: 'Jardineira Seleta', category: 'congelados' },
        { id: 20, img: polenta, title: 'Polenta Bem Brasil', category: 'congelados' },
        { id: 21, img: vagem, title: 'Vagem Inteira Francesa', category: 'congelados' },

        { id: 22, img: batataMcCain, title: 'Batata McCain', category: 'congelados' },
        { id: 23, img: chickenQueijo, title: 'Chicken de Queijo', category: 'congelados' },
        { id: 24, img: chickenTradicional, title: 'Chicken Tradicional', category: 'congelados' },
        { id: 25, img: ervilhaCongelada, title: 'Ervilha Congelada ', category: 'congelados' },
        { id: 26, img: filezinhoEmpanado, title: 'Filézinho Empanado', category: 'congelados' },
        { id: 27, img: mandiocaCongelado, title: 'Mandioca Congelada  ', category: 'congelados' },
        { id: 28, img: milhoCongelado, title: 'milho Congelado  ', category: 'congelados' },
        { id: 29, img: polentaCongelado, title: 'Polenta Congelada  ', category: 'congelados' },
        { id: 30, img: steakFrango, title: 'Steak de Frango  ', category: 'congelados' },
        { id: 31, img: torresmoMoido, title: 'Torresmo Moido  ', category: 'congelados' },
        { id: 32, img: hamburgue, title: 'Hambúrguer', category: 'congelados' },


        { id: 31, img: coracaoDeFrango, title: 'Coração de Frango', category: 'aves' },
        { id: 32, img: meioDaAsa, title: 'Meio da asa', category: 'aves' },
        { id: 33, img: sassami, title: 'Sassami', category: 'aves' },
        { id: 34, img: fileDeFrango, title: 'Filé de Frango (peito s/ osso)', category: 'aves' },
        { id: 35, img: fileDeCoxa, title: 'Filé de Coxa', category: 'aves' },
        { id: 36, img: asaDeFrango, title: 'Asa de Frango', category: 'aves' },
        { id: 37, img: peitoDeFrango, title: 'Peito de Frango c/ osso', category: 'aves' },
        { id: 38, img: coxinhaDaAsa, title: 'Coxinha da Asa', category: 'aves' },
        { id: 39, img: coxaSobrecoxa, title: 'Coxa e Sobrecoxa', category: 'aves' },
        { id: 40, img: carcacaoDeFrango, title: 'Carcaça de Frango 1.9Kg', category: 'aves' },
        { id: 42, img: frangoResfriado, title: 'Frango Resfriado', category: 'aves' },
        { id: 42, img: moela, title: 'Moela', category: 'aves' },
        { id: 43, img: galinha, title: 'Galinha Pesada', category: 'aves' },

        { id: 44, img: picanha, title: 'Picanha Uruguaia Steakhouse', category: 'bovinos' },
        { id: 45, img: fileMignon, title: 'Filé Mignon s/ Cordão', category: 'bovinos' },
        { id: 46, img: patinho, title: 'Patinho', category: 'bovinos' },
        { id: 47, img: alcatra, title: 'Alcatra c/ Maminha', category: 'bovinos' },
        { id: 48, img: coxaoMole, title: 'Coxão Mole', category: 'bovinos' },
        { id: 49, img: coxaoDuro, title: 'Coxão Duro', category: 'bovinos' },
        { id: 50, img: capaFile, title: 'Capa de Filé', category: 'bovinos' },
        { id: 51, img: peitoBovino, title: 'Peito Bovino', category: 'bovinos' },
        { id: 52, img: acem, title: 'Acém', category: 'bovinos' },
        { id: 53, img: costelaBovina, title: 'Costela Bovina', category: 'bovinos' },
        { id: 54, img: carneMoida, title: 'Carne Moída (Pct 2kg)', category: 'bovinos' },
        { id: 55, img: diafragma, title: 'Diafragma', category: 'bovinos' },
        { id: 56, img: sangria, title: 'Sangria', category: 'bovinos' },

        { id: 58, img: barriga, title: 'Barriga', category: 'suinos' },
        { id: 59, img: costelinha, title: 'Costelinha', category: 'suinos' },
        { id: 60, img: carre, title: 'Carré', category: 'suinos' },
        { id: 61, img: bisteca, title: 'Bisteca', category: 'suinos' },
        { id: 62, img: pernil, title: 'Pernil c/ osso c/ pele', category: 'suinos' },
        { id: 63, img: tripa, title: 'Tripa Suína (Culatra)', category: 'suinos' },
        { id: 64, img: papada, title: 'Papada c/ Pele', category: 'suinos' },
        { id: 65, img: paleta, title: 'Sobre Paleta c/ Osso', category: 'suinos' },
        { id: 66, img: toucinhoCortado, title: 'Toucinho Cortado p/ Torresmo', category: 'suinos' },
        { id: 67, img: toucinhoPele, title: 'Toucinho c/ Pele', category: 'suinos' },

        { id: 68, img: bacalhau, title: 'Bacalhau Desfiado', category: 'peixe' },
        { id: 69, img: merluzao, title: 'Merluzão', category: 'peixe' },
        { id: 70, img: merluzaArgentina, title: 'Merluza Argentina', category: 'peixe' },
        { id: 71, img: fileTilapia, title: 'Filé de Tilapia', category: 'peixe' },
        { id: 72, img: filePangassius, title: 'Filé de Pangassius', category: 'peixe' },
        { id: 73, img: filePescadaBranca, title: 'Filé de Pescada Branca', category: 'peixe' },
        { id: 74, img: cacao, title: 'Cação', category: 'peixe' },
        { id: 75, img: porquinhoLimpo, title: 'Porquinho Limpo (s/ cabeça)', category: 'peixe' },
        { id: 76, img: sardinhaEspalmada, title: 'Sardinha Espalmada', category: 'peixe' },
        { id: 77, img: sardinhaSemCabeca, title: 'Sardinha (s/ cabeça)', category: 'peixe' },
        { id: 78, img: camarao7Barbas, title: 'Camarão 7 Barbas (Pct 5kg)', category: 'peixe' },

        { id: 79, img: champignonFatiado, title: 'Champignon Fatiado', category: 'conservas-secos' },
        { id: 80, img: ovoCodorna, title: 'Ovo de Codorna', category: 'conservas-secos' },
        { id: 81, img: palmitoPicado, title: 'Palmito Picado', category: 'conservas-secos' },
        { id: 82, img: palmitoRodela, title: 'Palmito Rodela', category: 'conservas-secos' },
        { id: 83, img: tomateSeco, title: 'Tomate Seco', category: 'conservas-secos' },
        { id: 84, img: pimentaBiquinho, title: 'Pimenta Biquinho Vermelha', category: 'conservas-secos' },
        { id: 85, img: atumRalado, title: 'Atum Ralado Tours', category: 'conservas-secos' },
        { id: 86, img: chicharro, title: 'Chicharro', category: 'conservas-secos' },
        { id: 87, img: azeitonaSemCaroco, title: 'Azeitona Verde s/ Caroço', category: 'conservas-secos' },
        { id: 88, img: azeitonaFatiada, title: 'Azeitona Verde Fatiada', category: 'conservas-secos' },
        { id: 89, img: azeitonaComCaroco, title: 'Azeitona Verde c/ Caroço Pequena', category: 'conservas-secos' },
        { id: 90, img: azeitonaComCaroco, title: 'Azeitona Verde c/ Caroço Grande', category: 'conservas-secos' },
        { id: 91, img: azeitonaPretaSemcaroco, title: 'Azeitona Preta s/ Caroço', category: 'conservas-secos' },
        // { id: 92, img: '', title: 'Azeitona Preta Pequena', category: 'conservas-secos' },
        { id: 93, img: azeitonaPretaFatiada, title: 'Azeitona Preta Fatiada', category: 'conservas-secos' },
        { id: 94, img: azeitonaChilena, title: 'Azeitona Chilena Grande', category: 'conservas-secos' },
        { id: 95, img: azeitonaBarrica, title: 'Azeitona Verde 28x32', category: 'conservas-secos' },
        { id: 96, img: azeitonaBarrica, title: 'Azeitona Verde 16x20', category: 'conservas-secos' },
        { id: 97, img: amacianteCarne, title: 'Amaciante de Carne', category: 'conservas-secos' },
        { id: 98, img: caldoCarne, title: 'Caldo de Carnes', category: 'conservas-secos' },
        { id: 99, img: caldoGalinha, title: 'Caldo de galinha', category: 'conservas-secos' },
        { id: 100, img: chimichurri, title: 'Chimichurri', category: 'conservas-secos' },
        { id: 101, img: colorifico, title: 'Colorifico', category: 'conservas-secos' },
        { id: 102, img: cominho, title: 'Cominho', category: 'conservas-secos' },
        { id: 103, img: condimentoPimenta, title: 'Condimento Pimenta', category: 'conservas-secos' },
        { id: 104, img: cremeCebola, title: 'Creme de cebola', category: 'conservas-secos' },
        { id: 105, img: oreganoFlocos, title: 'Oregano flocos', category: 'conservas-secos' },
        { id: 106, img: tempeiroBaiano, title: 'Tempero Baiano', category: 'conservas-secos' },
        { id: 107, img: feijaoCarioca, title: 'Feijão Carioca 30x1', category: 'conservas-secos' },
        { id: 108, img: feijaoPreto, title: 'Feijão Preto 10x1', category: 'conservas-secos' },
        
        //novos 
        { id: 108, img: doceDeLeite , title: 'Doce de Leite ', category: 'conservas-secos' },
        { id: 108, img: ketchupGalaoSache , title: ' Ketchuo Galão e Sache ', category: 'conservas-secos' },
        { id: 108, img: maioneseBalseSache, title: 'Maionese Balde e Sache  ', category: 'conservas-secos' },
        { id: 108, img: molhoPizza , title: ' Molho de Pizza ', category: 'conservas-secos' },
        { id: 108, img: mostardaGalaoSache , title: ' Mostarda Galão e Sache ', category: 'conservas-secos' },



        { id: 109, img: costelaSalgada, title: 'Costela Salgada', category: 'feijoada' },
        { id: 110, img: raboSalgado, title: 'Rabo Salgado', category: 'feijoada' },
        { id: 111, img: pontaCostela, title: 'Ponta de Costela', category: 'feijoada' },
        { id: 112, img: peSalgado, title: 'Pé Salgado', category: 'feijoada' },
        { id: 113, img: orelhaSalgada, title: 'Orelha Salgada', category: 'feijoada' },
        { id: 114, img: linguaSalgada, title: 'Língua Salgada', category: 'feijoada' },
        { id: 115, img: costelaSalgada, title: 'Costela Salgada', category: 'feijoada' },
        { id: 116, img: lomboSalgado, title: 'Lombo Salgado', category: 'feijoada' },
        { id: 117, img: coxaoDuroFeijoada, title: 'Carne seca Coxão duro ', category: 'feijoada' },
        { id: 118, img: traseiroFeijoada, title: 'Carne seca Traseiro ', category: 'feijoada' },
        // { id: 119, img: , title: 'Dianteiro (Pct 5kg)', category: 'feijoada' },
        { id: 129, img: dianteiro, title: 'Carne seca Dianteiro (Pct 400gr)', category: 'feijoada' },    

        //muiudos virou bovidos
        { id: 130, img: bofeBovino , title: 'Bofe Bovino', category: 'bovinos' },
        { id: 131, img: buchoBovino, title: 'Bucho bovino', category: 'bovinos' },
        { id: 132, img: carneIndustrial, title: ' Carne Industrial', category: 'bovinos' },
        { id: 133, img: coracaoBovino, title: 'Coração Bovino', category: 'bovinos' },
        { id: 133, img: cupimBovino, title: 'Cupim Bovino', category: 'bovinos' },
        { id: 134, img: figadoBovino, title: 'Fígado Bovino', category: 'bovinos' },
        { id: 135, img: fressuraSuina, title: 'Fressura Suína', category: 'bovinos' },
        { id: 136, img: linguaBovina, title: 'Língua Bovina', category: 'bovinos' },
        { id: 137, img: mocotoBovino, title: 'Mocóto Bovino', category: 'bovinos' },
        { id: 138, img: mocotoCortado, title: 'Mocotó Cortado', category: 'bovinos' },
        { id: 139, img: passarinha, title: 'Passarinha', category: 'bovinos' },
        { id: 140, img: rabada, title: 'Rabada Bovina', category: 'bovinos' },
        { id: 141, img: rimBovino, title: 'Rim Bovino', category: 'bovinos' },
        { id: 142, img: tripaBovina, title: 'Tripa Bovina', category: 'bovinos' },
        //

        { id: 143, img: queijoMussarela, title: 'Queijo Mussarela', category: 'frios' },
        { id: 144, img: queijoPrato, title: 'Queijo Prato', category: 'frios' },
        { id: 145, img: mortadela, title: 'Mortadela', category: 'frios' },
        { id: 146, img: presunto, title: 'Presunto', category: 'frios' },
        { id: 147, img: apresuntado, title: 'Apresuntado', category: 'frios' },
        { id: 148, img: cheddarFatiado, title: 'Cheddar Fatiado', category: 'frios' },
        { id: 149, img: margarinaPote, title: 'Margarina Pote', category: 'frios' },
        { id: 150, img: manteigaCaixa, title: 'Manteiga Caixa c/s sal', category: 'frios' },
        { id: 151, img: parmesãoInteiro, title: 'Parmesão Inteiro', category: 'frios' },
        { id: 152, img: peitoDePeru, title: 'Peito de Peru', category: 'frios' },
        { id: 153, img: provolone, title: 'Provolone', category: 'frios' },
        { id: 154, img: queijoCoalho, title: 'Queijo Coalho', category: 'frios' },
        { id: 155, img: queijoRalado, title: 'Queijo ralado', category: 'frios' },
        { id: 156, img: requeijãoBisnaga, title: 'Requeijão Bisnaga', category: 'frios' },
        { id: 157, img: requeijãoPote, title: 'Requeijão Pote', category: 'frios' },
        { id: 158, img: requeijãoDoNorte, title: 'Requeijão do Norte', category: 'frios' },
        { id: 159, img: salame, title: 'Salame', category: 'frios' },
        { id: 160, img: cheddarBisnaga, title: 'Cheddar Bisnaga', category: 'frios' },
    ]
};

export default data;

import NossaEstrutura from '../../components/Compo-QuemSomos/Estrutura'
import CompoHistoria from '../../components/Compo-QuemSomos/Historia'
import MisVisVal from '../../components/Compo-QuemSomos/MisVisVal'

export default function QuemSomos(){

    return(
        <>
        <CompoHistoria/>
        <MisVisVal/>
        <NossaEstrutura/>
        </>
    )
}